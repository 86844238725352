var state = {
  administrator: 'administrator',
  // 系统管理员
  systemOperator: 'systemOperator',
  // 系统操作员
  systemMonitor: 'systemMonitor',
  // 系统监察员
  corpManager: 'corpManager',
  // 商户管理
  operator: 'operator',
  // 操作员
  monitor: 'monitor',
  // 监察员
  handler: 'handler',
  // 经办员
  cashier: 'cashier',
  // 出纳员
  invoiceManager: 'invoiceManager' // 系统开票
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};