var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "keep-alive",
        [
          _vm.cachedViews.indexOf(_vm.$route.name) != -1
            ? _c("router-view", { key: _vm.key })
            : _vm._e(),
        ],
        1
      ),
      _vm.cachedViews.indexOf(_vm.$route.name) == -1
        ? _c("router-view", { key: _vm.key })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }