import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: data
  });
}
export function refresh(data) {
  return request({
    url: '/auth/refresh',
    method: 'post',
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: '/auth/me',
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
}
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  });
}
export function fetchUserList(query) {
  return request({
    url: '/users',
    method: 'get',
    params: query
  });
}
export function createUser(data) {
  return request({
    url: '/users',
    method: 'post',
    data: data
  });
}
export function updateUser(data) {
  return request({
    url: '/users/' + data.id,
    method: 'put',
    data: data
  });
}
export function deleteUser(id) {
  return request({
    url: '/users/' + id,
    method: 'delete'
  });
}
export function changePassword(data) {
  return request({
    url: '/auth/change_password',
    method: 'post',
    data: data
  });
}
export function refreshToken() {
  return request({
    baseURL: process.env.VUE_APP_PDS_URL,
    url: '/api/auth/refresh_token',
    method: 'get'
  });
}
export function adminUserList(query) {
  return request({
    url: '/admin/user_list',
    method: 'get',
    params: query
  });
}