import Vue from 'vue';
Vue.directive('permission', {
  inserted: function inserted(el, binding, vnode) {
    if (!binding) return; // 假值返回
    var roleAuthList = vnode.context.$store.getters.roleAuthList;
    if (!roleAuthList[binding.value]) {
      try {
        el.remove();
      } catch (e) {
        el.parentNode.removeChild();
      }
    }
  }
});