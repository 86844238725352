import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var title = process.env.VUE_APP_TITLE;
var is_demi = process.env.VUE_APP_IS_DEMI === 'true';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: title,
      is_demi: is_demi,
      logo: true
    };
  },
  computed: {
    // 是否为花朵云系统
    isHDY: function isHDY() {
      return window.location.host.includes('huaduoyun');
    }
  }
};