import Vue from 'vue';
import Router from 'vue-router';
// import store from '@/store'
// import Cookies from 'js-cookie'
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/register',
  component: import('@/views/register/index'),
  // redirect: '/register/index',
  name: 'register',
  meta: {
    title: '注册',
    icon: 'excel'
  },
  hidden: true,
  children: [{
    path: 'index',
    name: 'registerIndex',
    component: function component() {
      return import('@/views/register/step1');
    },
    meta: {
      title: '注册'
    }
  }]
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '首页',
      icon: 'dashboard'
    }
  }]
}];
export var asyncRoutes = [{
  path: '/balance',
  component: Layout,
  redirect: '/balance/index',
  name: 'balance',
  meta: {
    title: '财务',
    icon: 'excel',
    roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'monitor', 'cashier', 'handler', 'spRoleJian']
  },
  children: [{
    path: 'index',
    name: 'BalanceIndex',
    component: function component() {
      return import('@/views/balance/index');
    },
    meta: {
      title: '余额',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'monitor', 'cashier', 'handler'],
      cache: true
    }
  }, {
    path: 'logs',
    name: 'BalanceLog',
    component: function component() {
      return import('@/views/balance/log');
    },
    meta: {
      title: '充值记录',
      cache: true,
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'cashier']
    }
  }, {
    path: 'payment_order',
    name: 'PaymentOrder',
    component: function component() {
      return import('@/views/balance/payment_order');
    },
    meta: {
      title: '资金明细',
      cache: true,
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'monitor', 'cashier', 'handler', 'spRoleJian']
    }
  }, {
    path: 'report',
    name: 'BalanceReport',
    hidden: true,
    component: function component() {
      return import('@/views/balance/report');
    },
    meta: {
      title: '总表'
    }
  }, {
    path: 'statistic',
    name: 'statistic',
    component: function component() {
      return import('@/views/balance/statistic');
    },
    meta: {
      title: '出款统计',
      roles: ['administrator', 'systemOperator', 'systemMonitor']
    }
  }, {
    path: 'summary',
    name: 'summary',
    component: function component() {
      return import('@/views/balance/summary');
    },
    meta: {
      title: '个人汇总',
      roles: ['administrator', 'systemOperator', 'systemMonitor']
    }
  }]
}, {
  path: '/transactions',
  component: Layout,
  redirect: '/transactions/index',
  name: 'transaction',
  meta: {
    title: '转账',
    icon: 'form',
    roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'handler', 'monitor', 'cashier', 'invoiceManager'],
    cache: true
  },
  children: [{
    path: 'index',
    name: 'transIndex',
    component: function component() {
      return import('@/views/transaction/index');
    },
    meta: {
      title: '转账记录',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'handler', 'monitor', 'cashier', 'invoiceManager'],
      cache: true
    }
  }, {
    path: 'alipay_import',
    name: 'alipayImport',
    component: function component() {
      return import('@/views/transaction/alipayImport');
    },
    meta: {
      title: '转账到支付宝账号',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'handler']
    }
  }, {
    path: 'import',
    name: 'transImport',
    component: function component() {
      return import('@/views/transaction/llpayImport');
    },
    meta: {
      title: '转账到银行卡',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'handler']
    }
  }, {
    path: 'create',
    name: 'transCreate',
    component: function component() {
      return import('@/views/transaction/create');
    },
    meta: {
      title: '转账到银行卡（表单录入）',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'handler']
    }
  }, {
    path: 'list',
    name: 'transList',
    component: function component() {
      return import('@/views/transaction/list');
    },
    meta: {
      title: '批次明细'
    },
    hidden: true
  }]
}, {
  path: '/Invoice',
  component: Layout,
  redirect: '/Invoice/index',
  name: 'Invoice',
  meta: {
    title: '发票系统',
    icon: 'invoice',
    roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'monitor', 'handler', 'invoiceManager']
  },
  children: [{
    path: 'recording',
    name: 'recording',
    component: function component() {
      return import('@/views/Invoice/recording');
    },
    meta: {
      title: '开票记录',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'monitor', 'handler', 'invoiceManager'],
      cache: true
    }
  }, {
    path: 'index',
    name: 'InvoiceIndex',
    component: function component() {
      return import('@/views/Invoice/index');
    },
    meta: {
      title: '申请开票',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'monitor', 'handler']
    }
  }, {
    path: 'voucherUpload',
    name: 'voucherUpload',
    component: function component() {
      return import('@/views/Invoice/voucherUpload');
    },
    meta: {
      title: '申请开票',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'monitor', 'handler']
    },
    hidden: true
  }, {
    path: 'information',
    name: 'information',
    component: function component() {
      return import('@/views/Invoice/information');
    },
    meta: {
      title: '开票资料',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'operator', 'monitor', 'handler']
    }
  }]
}, {
  path: '/corporations',
  component: Layout,
  redirect: '/corporations/index',
  name: 'corporation',
  meta: {
    title: '管理',
    icon: 'tree',
    roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'monitor']
  },
  children: [{
    path: 'index',
    name: 'CorpsIndex',
    component: function component() {
      return import('@/views/corporation/index');
    },
    meta: {
      title: '商户',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'monitor']
    }
  }, {
    path: 'setting',
    name: 'settingIndex',
    component: function component() {
      return import('@/views/corporation/setting');
    },
    hidden: true,
    meta: {
      title: '资料配置',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'monitor']
    }
  }, {
    path: 'docking',
    name: 'dockingIndex',
    component: function component() {
      return import('@/views/corporation/docking');
    },
    hidden: true,
    meta: {
      title: '对接管理',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'monitor']
    }
  }, {
    path: 'users',
    name: 'CorpUser',
    component: function component() {
      return import('@/views/corporation/user');
    },
    meta: {
      title: '用户',
      roles: ['administrator', 'systemOperator', 'systemMonitor', 'corpManager', 'monitor'],
      cache: true
    }
  }, {
    path: 'userPermission',
    name: 'userPermission',
    component: function component() {
      return import('@/views/corporation/userPermission');
    },
    meta: {
      title: '权限',
      roles: ['administrator']
    },
    hidden: true
  }, {
    path: 'taxSourceManagement',
    name: 'taxSourceManagement',
    component: function component() {
      return import('@/views/corporation/taxSourceManagement');
    },
    meta: {
      title: '主体管理',
      roles: ['administrator', 'systemOperator', 'systemMonitor'],
      cache: true
    }
  }, {
    path: 'noticeManager',
    name: 'noticeManager',
    component: function component() {
      return import('@/views/notice/index');
    },
    meta: {
      title: '公告管理',
      roles: ['administrator'],
      cache: true
    }
  }]
},
// {
//   path: '/systemBulletin',
//   component: Layout,
//   redirect: '/systemBulletin/index',
//   name: 'systemBulletin',
//   meta: { title: '系统公告', icon: 'tree' },
//   children: [
//     {
//       path: 'index',
//       name: 'systemBulletinIndex',
//       component: () => import('@/views/bulletin/index'),
//       meta: { title: '系统公告', cache: true }
//     },
//     {
//       path: 'users',
//       name: 'CorpUser',
//       component: () => import('@/views/corporation/user'),
//       meta: { title: '发布公告', roles: ['administrator', 'systemOperator', 'systemMonitor'], cache: true }
//     }
//   ]
// },
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    mode: 'hash',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// 全局路由守卫
// router.beforeEach((to, from, next) => {
//   if (Number(Cookies.get('expire')) - new Date().getTime() <= (6 * (60 * 60) * 1000)) {
//     store.dispatch('user/refreshToken')
//   }
//   next() // 必须使用 next ,执行效果依赖 next 方法的调用参数
// })

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;