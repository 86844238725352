import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppMain',
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  },
  watch: {
    $route: {
      handler: function handler(val, oldVal) {
        var name = val.name;
        if (name) {
          this.$store.dispatch('tagsView/addView', val);
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  }
};