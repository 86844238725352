import Cookies from 'js-cookie';
var TokenKey = 'qy-Token';
var ExpireKey = 'qy-expire';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token, strDate) {
  var d = new Date(strDate);
  return Cookies.set(TokenKey, token, {
    'Expires': d.toGMTString(),
    domain: process.env.VUE_APP_DOMAIN
  });
}
export function getExpire() {
  return Cookies.get(ExpireKey);
}
export function setExpire(token, strDate) {
  var d = new Date(strDate);
  return Cookies.set(ExpireKey, token, {
    'Expires': d.toGMTString(),
    domain: process.env.VUE_APP_DOMAIN
  });
}
export function removeToken() {
  return Cookies.remove(TokenKey, {
    domain: process.env.VUE_APP_DOMAIN
  });
}