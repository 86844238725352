var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.url != ""
        ? _c("div", { on: { click: _vm.clickRouter } }, [_vm._t("default")], 2)
        : _c(
            "component",
            _vm._b({}, "component", _vm.linkProps(_vm.to), false),
            [_vm._t("default")],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }