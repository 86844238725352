import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  name: function name(state) {
    return state.user.name;
  },
  corporationID: function corporationID(state) {
    return state.user.corporationID;
  },
  corporationName: function corporationName(state) {
    return state.user.corporationName;
  },
  balance: function balance(state) {
    return state.user.balance;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  transactionCorporationID: function transactionCorporationID(state) {
    return state.transaction.corporationID;
  },
  transactionCorporationName: function transactionCorporationName(state) {
    return state.transaction.corporationName;
  },
  limitllMoney: function limitllMoney(state) {
    return state.settings.limitllMoney;
  },
  user: function user(state) {
    return state.user.user;
  },
  expire: function expire(state) {
    return state.user.expire;
  },
  sort: function sort(state) {
    return state.sort;
  },
  check: function check(state) {
    return state.check;
  },
  getNotice: function getNotice(state) {
    return state.notice;
  },
  invoice: function invoice(state) {
    return state.invoice;
  },
  PaymentChannel: function PaymentChannel(state) {
    return state.corporation.PaymentChannel;
  },
  PaymentChannelKeyValue: function PaymentChannelKeyValue(state) {
    return state.corporation.PaymentChannel.reduce(function (acc, cur) {
      acc[cur.id] = cur.name;
      return acc;
    }, {});
  }
};
export default getters;