//
//
//
//
//
//

// eslint-disable-next-line no-unused-vars
var refreshToken;
// import { getToken } from '@/utils/auth'
export default {
  name: 'App',
  created: function created() {
    //     clearInterval(refreshToken)
    //     const fun = () => {
    //       console.log('检测token', getToken())
    //       if (this.$store.getters.expire - new Date().getTime() <= 3600000) {
    //         this.$store.dispatch('user/refreshToken').catch((data) => {
    //           clearInterval(refreshToken)
    //         })
    //       }
    //     }
    //     if (this.$store.getters.expire !== 0) {
    //       fun()
    //       refreshToken = setInterval(fun, 1000)
    //     }
  }
};