import "core-js/modules/es6.function.name";
var state = {
  corporationID: 0,
  corporationName: ''
};
var mutations = {
  SET_CORPORATION_ID: function SET_CORPORATION_ID(state, id) {
    state.corporationID = id;
  },
  SET_CORPORATION_NAME: function SET_CORPORATION_NAME(state, name) {
    state.corporationName = name;
  }
};
var actions = {
  updateTransactionCorporation: function updateTransactionCorporation(_ref, data) {
    var commit = _ref.commit;
    commit('SET_CORPORATION_ID', data.id);
    commit('SET_CORPORATION_NAME', data.name);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};