var state = {
  isCheckPhone: true,
  isCheckBankNumber: true,
  id_verify: false,
  age_verify: false,
  id_4f_verify: false,
  contract_verify: false
};
var mutations = {
  CHANGE: function CHANGE(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  change: function change(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};