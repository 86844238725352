var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _vm.isHDY
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: "logohuaduoyun.png" },
                      })
                    : _vm.is_demi
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: "logo_demi.png" },
                      })
                    : _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: require("@/assets/logo.png"), alt: "" },
                      }),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _vm.is_demi
                    ? [
                        _vm.isHDY
                          ? _c("img", {
                              staticClass: "sidebar-logo",
                              attrs: { src: "logohuaduoyun.png" },
                            })
                          : _c("img", {
                              staticClass: "sidebar-logo",
                              attrs: { src: "logo_demi.png" },
                            }),
                        _c(
                          "h1",
                          { staticClass: "sidebar-title" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.isHDY ? "花朵云" : _vm.title) +
                                " "
                            ),
                            _c("el-divider", {
                              staticClass: "divider",
                              attrs: { direction: "vertical" },
                            }),
                            _vm._v(" 财税 "),
                          ],
                          1
                        ),
                      ]
                    : [
                        _vm.logo
                          ? _c("img", {
                              staticClass: "sidebar-logo-hover",
                              attrs: { src: "logo_hover.png" },
                            })
                          : _vm._e(),
                      ],
                ],
                2
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }